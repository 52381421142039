import * as React from "react";
import { MarqueeRegionProps } from "./typings";
import {
  MarqueeCardType,
  SingleCardMarquee as SingleCardMarqueeAd,
} from "@shared-ui/retail-sponsored-content-marquee-cards";
import { withStores } from "stores";
import { observer } from "mobx-react";
import { hasRenderableChildren } from "src/stores/ExperienceTemplateStore/shouldRender";
import { RegionChildren } from "src/utils/RegionUtils";

export const MarqueeAdRegionComponent = withStores(
  "flexModuleModelStore",
  "context"
)(
  observer((props: MarqueeRegionProps) => {
    const { flexModuleModelStore, templateComponent, blossomComponent, context } = props;

    if (!templateComponent || !blossomComponent || !hasRenderableChildren(templateComponent, flexModuleModelStore)) {
      return null;
    }

    const { config } = templateComponent;
    const { uci, pageName, variant } = config;
    const cardType: MarqueeCardType = uci === "NMC1" ? MarqueeCardType.CENTER_STAGE : MarqueeCardType.COLLECTION;

    const inputs = {
      context,
      sponsoredContentContext: {
        pageName,
        sponsoredContentId: uci,
        variant,
      },
    };

    const enableAcceptableAds = uci !== "NMC1";

    return (
      <div className="MarqueeAdRegion" id={`marquee-${uci}`}>
        <SingleCardMarqueeAd skipSsr inputs={inputs} enableAcceptableAds={enableAcceptableAds} cardType={cardType}>
          <RegionChildren templateComponent={templateComponent} blossomComponent={blossomComponent} />
        </SingleCardMarqueeAd>
      </div>
    );
  })
);

export const MarqueeAdRegion = MarqueeAdRegionComponent;

export default MarqueeAdRegion;
